import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

// Services
import { HttpService } from "./http.service";

// Models
import { Ata } from "./../models/class/ata";
import { Unit } from "./../models/class/unit";
import { Maker } from "./../models/class/maker";
import { Build } from "../models/class/build";
import { Fleet } from "./../models/class/fleet";
import { Airport } from "../models/class/airport";
import { SubUnit } from "../models/class/subUnit";
import { Machine } from "./../models/class/machine";
import { Disposal } from "./../models/class/disposal";
import { ToolType } from "./../models/class/toolType";
import { SparePart } from "./../models/class/sparePart";
import { TypeState } from "./../models/class/typeState";
import { PartNumber } from "../models/class/partNumber";
import { SystemType } from "./../models/class/systemType";
import { FleetFamily } from "./../models/class/fleetFamily";
import { Departament } from "./../models/class/departament";
import { RoleMachine } from "./../models/class/roleMachine";
import { ZoneMachine } from "./../models/class/zoneMachine";
import { TypeInspects } from "./../models/class/typeInspect";
import { InventoryToolPart } from "../models/class/inventoryToolPart";
import { ComponentAircraft } from "../models/class/component";
import { InventoryDisposal } from "../models/class/inventoryDisposal";
import { InventorySparePart } from "../models/class/inventorySparePart";
import { map } from "rxjs/internal/operators/map";
import { UnitHistory } from "../models/class/unitHistory";
import { Region } from "../models/class/region";
import { take } from "rxjs/operators";
import { ImageType } from "../models/class/imagetype";
import { DocumentType } from "../models/class/documenttype";
import { MachineImage } from "../models/class/machineimage";
import { MachineDocument } from "../models/class/machinedocument";
import { AssetStatus } from "../models/class/assetstatus";

@Injectable({
  providedIn: "root",
})
export class MachineService {

  constructor(private _httpService: HttpService) { }

  // Funciones Propias

  getAllHelicopters(comandID: number, unitID: number): Observable<Machine[]> {
    let whereClause = "";

    if (unitID != 0) whereClause = `"where":{"fixedUnitID":${unitID}},`;

    let url: string = `Commands/${comandID}/Machines?filter={${whereClause}"include":[
    {"relation": "dailyUseHistories", "scope": {"order": "id DESC" , "limit":3, "include": { "relation": "typeState", "scope": { "include": {"relation": "stateName", "scope":  {"where":{"commandID":${comandID}} } }} } }},
    {"relation": "maintHistories", "scope": {"order": [ "hoursUseNext ASC" , "id DESC" ] , "limit":10, "include":["maintCmpActivity","unit","user","failReports"] }},
    {"relation": "typeState", "scope": { "include": {"relation": "stateName", "scope":  {"where":{"commandID":${comandID}} } }}},
    {"relation": "components", "scope": {"order": "id DESC" , "limit":5, "include":[
       "systemType", {"relation": "maintHistories", "scope": {"order": "id DESC" , "limit":1, "include":["maintCmpActivity"] }}
       ]}
    },
    {"relation": "unitHistories", "scope": {  "limit": 1 ,  "order" : "id DESC"  }},
    "fleet", "unit", "airport", "departament"]}`;
    return this._httpService.httpGet(url);
  }

  getDataHelicopter(id: number, unitID?: number): Observable<Machine> {
    let url: string = `Machines?filter={"where":{"id":${id}},"include":[{"relation": "dailyUseHistories", "scope": {"order": "id DESC" , "limit":3 }},{"relation": "useHistoryAugmenteds", "scope": {"order": "id DESC" , "limit":3 }}, "typeState", "fleet", "unit", "airport"]}`;
    return this._httpService.httpGet(url);
  }

  get(filter?: string): Observable<Machine[]> {
    let url: string = `Machines?filter=${filter != undefined ?  filter : ""}`;
    return this._httpService.httpGet(url);
  }

  getMachineById(machineID: number, filter?: string): Observable<Machine> {
    let url: string = `Machines/${machineID}`;
    if (filter)
      url += `?filter=${filter}`
    return this._httpService.httpGet(url);
  }

  getMachineByFleetId(fleetId: Number, commaindId: Number): Observable<Machine[]> {
    const _filter = {
      where: { "fleetID": fleetId, "commandID": commaindId },

      order: "callsing asc",
    }

    let url: string = `Machines?filter=${JSON.stringify(_filter)}`;

    return <Observable<Machine[]>>(
      this._httpService.httpGet(url)
    );
  }

  getAllMachine(): Observable<Machine[]> {
    let url: string = `Machines`;
    return <Observable<Machine[]>>(
      this._httpService.httpGet(url)
    );
  }

  setMachine(item: Machine): Observable<Machine> {
    let url: string = `Machines`;
    return <Observable<Machine>>(
      this._httpService.httpPost(url, item)
    );
  }

  getAllMachinesWithFilter(filter?: string): Observable<Machine[]> {
    const url: string = `Machines?filter=`
    return this._httpService.httpGet(url + (filter != undefined ? filter : "")).pipe(take(1));
  }

  getAllMachineByUnit(filter: string): Observable<Machine[]> {
    let url: string = `Machines`;
    return <Observable<Machine[]>>(
      this._httpService.httpGet(url + "?filter=" + filter).pipe(take(1))
    );
  }

  getComponentsByMachineID(
    machineID: number,
    filter?: string
  ): Observable<ComponentAircraft[]> {
    let url: string;
    if (filter) url = `Machines/${machineID}/components?filter=${filter}`;
    else url = `Machines/${machineID}/components`;
    return this._httpService.httpGet(url);
  }

  getAllHelicoptersByFleetID(
    unitID: number,
    fleetID: number
  ): Observable<Machine[]> {
    let url: string = `Fleets/${fleetID}/machines?filter={"where":{"commandId":${unitID}},
    "include":[{"relation": "dailyUseHistories", "scope": {"order": "id DESC" , "limit":3 }},
    {"relation": "maintHistories", "scope": {"order": "id DESC" , "limit":3, "include":["maintCmpActivity","unit","user"] }},
    {"relation": "typeState", "scope": { "include": {"relation": "stateName", "scope":  {"where":{"commandID":${unitID}} } }}},
    "fleet", "unit", "airport"]}`;
    return this._httpService.httpGet(url);
  }

  getKml(
    url: string
  ): Observable<{ id: number; title: string; color: string; points: any[] }> {
    return this._httpService.httpGetStorage(url);
  }
  getMachines(filter?: string): Observable<Machine[]> {
    let url: string = "Machines";
    if (filter) url += `?filter=${filter}`
    return this._httpService.httpGet(url);
  }

  updateMachine(id: number, data: Machine): Observable<Machine[]> {
    let url: string = "Machines/" + id;
    return this._httpService.httpPatch(url, data);
  }
  updateUnitHistory(data: string): Observable<UnitHistory> {
    let url: string = "UnitHistories";
    return this._httpService.httpPatch(url, data);
  }
  updateBuildSlot(id: any, data: any): Observable<Machine[]> {
    let url: string = "BuildSlots/" + id;
    return this._httpService.httpPatch(url, data);
  }

  getAllState(commandID: number): Observable<TypeState[]> {
    let url: string = `StateNames?filter={"include":{"relation":"typeStates"},"where":{"commandID":${commandID} } }`;
    return <Observable<TypeState[]>>(
      this._httpService.httpGet(url)
    );
  }

  getAllUnits(filter?: String): Observable<Unit[]> {
    let url: string = "Units";
    if (filter) url += `?filter=${filter ? filter : ""}`
    return this._httpService.httpGet(url);
  }

  getAllAta(): Observable<Ata[]> {
    let url: string = `AtaTypes?filter={"include":{"relation":"subAta"} }`;
    return this._httpService.httpGet(url);
  }

  getAllFleetFamily(): Observable<FleetFamily[]> {
    let url: string = "FleetFamilies";
    return this._httpService.httpGet(url);
  }

  getAllMakers(filter?: string): Observable<Maker[]> {
    let url: string = "Manufactures?filter="+(filter ? filter : "");
    return this._httpService.httpGet(url);
  }

  getAllFleet(manufactureId?: number, filter?: string): Observable<Fleet[]> {
    let url: string = "Fleets";
    if (manufactureId != null)
      url += `?filter={"where": {"manufactureID": "${manufactureId}"}}`;
    else if (filter) url = `Fleets?filter=${filter}`;
    return this._httpService.httpGet(url);
  }

  getFleets(filter?: string): Observable<Fleet[]> {
    let url: string = "Fleets";
    if (filter)
      url += `?filter=${filter}`;
    return this._httpService.httpGet(url);
  }

  getAllSystem(): Observable<SystemType[]> {
    let url: string = "SystemTypes";
    return this._httpService.httpGet(url);
  }
  getSystemTypeByID(id: number): Observable<SystemType> {
    let url: string = `SystemTypes/${id}`;
    return this._httpService.httpGet(url);
  }

  getAllDepartament(): Observable<Departament[]> {
    let url: string = "Departaments";
    return this._httpService.httpGet(url);
  }
  getAllRegions(): Observable<Region[]> {
    let url: string = "Regions";
    return this._httpService.httpGet(url);
  }


  getAllAirport(filter?: string): Observable<Airport[]> {
    let url: string = "Airports";
    if (!!filter)
      url += `?filter=${filter}`
    return this._httpService.httpGet(url);
  }

  getAllRoleMachine(): Observable<RoleMachine[]> {
    let url: string = 'RoleMachines?filter={"order": "roleMacName ASC"}';
    return this._httpService.httpGet(url);
  }
  getAllFleetsByRoleMachine(
    roleMachineID: number,
    filter?: string
  ): Observable<Fleet[]> {
    let url: string = `/RoleMachines/${roleMachineID}/fleets?filter=${filter}`;
    return this._httpService.httpGet(url);
  }

  getAllDisposals(): Observable<Disposal[]> {
    let url: string = "Disposals";
    return this._httpService.httpGet(url);
  }
  newDisposal(data): Observable<Disposal> {
    let url: string = "Disposals";
    return this._httpService.httpPost(url, data);
  }
  newInventoryDisposals(data): Observable<Disposal> {
    let url: string = "InventoryDisposals";
    return this._httpService.httpPost(url, data);
  }
  patchInventorySparePartsByID(id, data): Observable<InventorySparePart> {
    let url: string = `InventorySpareParts/${id}`;
    return this._httpService.httpPatch(url, data);
  }

  newBuildInfrastructure(data): Observable<Disposal> {
    let url: string = "BuildInfrastructures";
    return this._httpService.httpPost(url, data);
  }
  newBuildPortfolio(data): Observable<Disposal> {
    let url: string = "Buildings/buildingPortfolio";
    return this._httpService.httpPost(url, data);
  }

  deleteBuildInfrastructureByID(id: number): Observable<Disposal> {
    let url: string = `BuildInfrastructures/${id}`;
    return this._httpService.httpDelete(url);
  }

  getAllDisposalsBySubUnits(unitsID): Observable<Disposal[]> {
    let url: string = `Disposals?filter={"where" : { "subUnitID":  {"inq": ${unitsID} }}}`;
    return this._httpService.httpGet(url);
  }
  getAllBuildingsByUnitID(
    unitID: number,
    filter?: string
  ): Observable<Build[]> {
    let url: string;
    if (filter) url = `Units/${unitID}/buildings?filter=${filter}`;
    else url = `Units/${unitID}/buildings`;
    return this._httpService.httpGet(url);
  }

  getBuildingByID(id: number, filter?: string): Observable<Build> {
    let url: string;
    if (filter) url = `Buildings/${id}?filter=${filter}`;
    else url = `Buildings/${id}`;
    return this._httpService.httpGet(url);
  }
  getAllBuildingBySubUnitID(
    subUnitID: number,
    filter?: string
  ): Observable<Build[]> {
    let url: string;
    if (filter) url = `SubUnits/${subUnitID}/Buildings?filter=${filter}`;
    else url = `SubUnits/${subUnitID}/Buildings`;
    return this._httpService.httpGet(url);
  }
  newBuilding(data): Observable<Build> {
    let url: string = `Buildings`;
    return this._httpService.httpPost(url, data);
  }

  getAllZoneMachinesByFleet(fleeiID): Observable<ZoneMachine[]> {
    let url: string = `ZoneMachines?filter={"where" : { "fleeiID":  ${fleeiID}}}`;
    return this._httpService.httpGet(url);
  }

  getAllTypeInspects(): Observable<TypeInspects[]> {
    let url: string = "TypeInspects";
    return this._httpService.httpGet(url);
  }

  getAllSpareParts(filter?: string): Observable<SparePart[]> {
    let url: string;
    if (filter) url = `SpareParts?filter=${filter}`;
    else url = `SpareParts`;
    return this._httpService.httpGet(url);
  }
  newSparePart(data): Observable<SparePart> {
    let url: string = "SpareParts";
    return this._httpService.httpPost(url, data);
  }
  newInventorySparePart(data): Observable<SparePart> {
    let url: string = "InventorySpareParts";
    return this._httpService.httpPost(url, data);
  }
  newPartNumbers(data: PartNumber[]): Observable<SparePart[]> {
    let url: string = "PartNumbers/newPartNumbers";
    return this._httpService.httpPost(url, data);
  }

  newPartNumber(data): Observable<SparePart> {
    let url: string = "PartNumbers";
    return this._httpService.httpPost(url, data);
  }

  getAllToolTypes(): Observable<ToolType[]> {
    let url: string = "ToolTypes";
    return this._httpService.httpGet(url);
  }
  newToolType(data): Observable<ToolType> {
    let url: string = "ToolTypes";
    return this._httpService.httpPost(url, data);
  }

  newInventoryToolPart(data): Observable<InventoryToolPart> {
    let url: string = "InventoryToolParts";
    return this._httpService.httpPost(url, data);
  }

  getAllInventoryToolPartsBySubUnitID(
    subUnitID: number,
    filter?: string
  ): Observable<InventoryToolPart[]> {
    let url: string;
    if (filter)
      url = `SubUnits/${subUnitID}/inventoryToolParts?filter=${filter}`;
    else
      url = `SubUnits/${subUnitID}/inventoryToolParts?filter={"include":["toolType"]}`;
    return this._httpService.httpGet(url);
  }
  getAllInventoryDisposalsBySubUnitID(
    subUnitID: number,
    filter?: string
  ): Observable<InventoryDisposal[]> {
    let url: string;
    if (filter)
      url = `SubUnits/${subUnitID}/inventoryDisposals?filter=${filter}`;
    else
      url = `SubUnits/${subUnitID}/inventoryDisposals?filter={"include":["disposal"]}`;
    return this._httpService.httpGet(url);
  }

  getAllInventorySparePartsBySubUnitID(
    subUnitID: number,
    filter?: string
  ): Observable<InventorySparePart[]> {
    let url: string;
    if (filter)
      url = `SubUnits/${subUnitID}/inventorySpareParts?filter=${filter}`;
    else
      url = `SubUnits/${subUnitID}/inventorySpareParts?filter={"include":["sparePart", "machine"] }`;
    return this._httpService.httpGet(url);
  }
  getInventorySparePartsByID(
    sparePartID: number,
    filter?: string
  ): Observable<InventorySparePart> {
    let url: string;
    if (filter) url = `inventorySpareParts/${sparePartID}?filter=${filter}`;
    else url = `inventorySpareParts/${sparePartID}`;
    return this._httpService.httpGet(url);
  }

  getAllSubUnitsByUnitsID(unitsID: number): Observable<SubUnit[]> {
    let url: string = `SubUnits?filter={"where" : { "unitID":  {"inq": ${unitsID} }}}`;
    return this._httpService.httpGet(url);
  }

  getAllImageTypes(filter?: string): Observable<ImageType[]> {
    let url: string = `ImageTypes?filter=${filter ? filter : ""}`;
    return this._httpService.httpGet(url);
  }

  getAllDocumentTypes(filter: string): Observable<DocumentType[]> {
    let url: string = `DocumentTypes?filter=${filter}`;
    return this._httpService.httpGet(url);
  }

  // getAllAssetStatus(filter: string): Observable<AssetStatus[]> {
  //   let url: string = `assetstatuses?filter=${filter}`;
  //   return this._httpService.httpGet(url);
  // }

  async getAllAssetStatus(filter?: string) {
    return new Promise<any[]>((resolve) => {
      const url: string = `assetstatuses?filter=${(filter==undefined ? "" : filter) }`
      this._httpService.httpGet(url).subscribe(data =>{
        resolve(data)
      }, erro => {
        resolve([])
      })
    });
  }

  getmachineImage(machine_id: number, imagetype_id: number): Observable<MachineImage[]> {
    let url: string = `machineimages?filter={"where": {"machine_id": "${machine_id}", "imagetype_id": "${imagetype_id}"}}`;
    return this._httpService.httpGet(url);
  }

  postmachineImage(item: any): Observable<any> {
    let url: string = `machineimages`;
    return this._httpService.httpPost(url, item);
  }

  putmachineImage(item: any): Observable<MachineImage> {
    let url: string = `machineimages`;
    return this._httpService.httpPut(url, item);
  }

  deletemachineImage(item: any): Observable<any> {
    let url: string = `machineimages/${item.id}`;
    return this._httpService.httpDelete(url);
  }

  getmachineDocument(machine_id: number, documenttype_id: number): Observable<MachineDocument[]> {
    let url: string = `machinedocuments?filter={"where": {"machine_id": "${machine_id}", "documenttype_id": "${documenttype_id}"}}`;
    return this._httpService.httpGet(url);
  }

  postmachineDocument(item: any): Observable<MachineDocument> {
    let url: string = `machinedocuments`;
    return this._httpService.httpPost(url, item);
  }

  putmachineDocument(item: any): Observable<MachineDocument> {
    let url: string = `machinedocuments`;
    return this._httpService.httpPut(url, item);
  }

  deletemachineDocument(item: any): Observable<MachineDocument> {
    let url: string = `machinedocuments/${item.id}`;
    return this._httpService.httpDelete(url);
  }

  

}

function mapStates(res: any): TypeState[] {
  return res.map(mapState);
}

function mapState(res: any): TypeState {
  return new TypeState({
    typeStateName: res.stateName,
    colorState: res.colorState,
    colorGroup: "1",
    id: res.typeState.id,
  });
}
