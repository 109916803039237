import { Force } from './force';
import { Commandfleet } from './commandfleet';
import { Formation } from './formation';
import { StateName } from './stateName';
import { TypeState } from './typeState';
import { FleetFamily } from './fleetFamily';
import { AssetStatus } from './assetstatus';
import { CustomerType } from './customer-type';
import { IdentificationType } from './identification-type';
import { Role } from './role';
import { ServiceAssigMachine } from '../Interfaces/service-assig-machine';


export class Command {

	public id?: number;
	public commandName?: string;
    public commandAcronim?: number;
    public forceID?: number;
    public csvLink?: string;
    public image?: string;
    public fileName?:string
    public force?: Force;
    public commandfleets?: Commandfleet[];
    public formations?: Formation[];
    public stateNames?: StateName[];
    public typeStates?: TypeState[];
    public FleetFamily?: FleetFamily[];
    public assetstatus?: Array<AssetStatus>;
    public customerid?: number
    public customertypes?: CustomerType
    public identification?: string
    public address?: string
    public phone?: string
    public identificationid?: number
    public identificationtype?: IdentificationType
    public roles?: Role[] = []
    public serviceassigmachines?: ServiceAssigMachine[] = []

	constructor(a ?:any) {
		this.id = a ? a.id : null;
		this.commandName = a ? a.commandName : null;
        this.commandAcronim = a ? a.commandAcronim : null;
        this.forceID = a ? a.forceID : null;
        this.force = a ? a.force : null;
        this.csvLink = a ? a.csvLink : null;
        this.image = a ? a.image : null;
        this.FleetFamily = a ? a.FleetFamily : null;
        this.assetstatus = (a && a.assetstatus) ? new Array<AssetStatus>(a.assetstatus) : null;
        this.serviceassigmachines = (a && a.serviceassigmachines) ? new Array<ServiceAssigMachine>(a.serviceassigmachines) : null;
	}

}
